import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isTablet, isMobileOnly } from 'react-device-detect'
import { Box, Button, Flex, Text, Image } from 'rebass'
import { useSupportedBrowser, usePortraitScreen } from 'uneeq-react-core'
import { useTranslation, Trans } from 'react-i18next'
import styles from './styles'

import portaitImg from '../assets/landing/LandingPageVideoPortrait.jpg'
import landingImg from '../assets/landing/LandingPageVideo.jpg'
import portaitVideo from '../assets/landing/LandingPageVideoPortrait.mp4'
import landingVideo from '../assets/landing/LandingPageVideo.mp4'
import MayaUnsupportedBrowser from '../../customPlugins/MayaUnsupportedBrowser'
import MayaCloseButton from '../../customPlugins/MayaCloseButton/MayaCloseButton'
import LanguageSelector from '../../customPlugins/LanguageSelector/LanguageSelector'
import { LanguageOption } from '../../customPlugins/LanguageSelector/LanguageOption'

interface SophieVideoProps {
  desktopOnly: boolean
  widgetMode: boolean
  renderLanguageSelector: () => JSX.Element
  isSmallScreen: boolean
}

const SophieVideo = ({
  desktopOnly,
  widgetMode,
  renderLanguageSelector,
  isSmallScreen
}: SophieVideoProps) => {
  const portraitScreen = usePortraitScreen()

  const shouldShowPortraitVideo = useMemo(() => {
    if (desktopOnly) {
      return portraitScreen
    }

    return isSmallScreen
  }, [desktopOnly, isSmallScreen, portraitScreen])

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [portraitScreen])

  const vid = document.getElementById('sophieVideo')

  //loop video
  useEffect(() => {
    const restartVideo = () => {
      if (vid) {
        // @ts-ignore
        vid.currentTime = 0.1 //setting to zero breaks iOS 3.2, the value won't update, values smaller than 0.1 was causing bug as well.
        // @ts-ignore
        vid.play()
      }
    }
    // @ts-ignore
    vid && vid.addEventListener('ended', restartVideo, false)
    return () => {
      // @ts-ignore
      vid && vid.removeEventListener('ended', restartVideo)
    }
  }, [vid])

  return (
    <Box sx={{ position: 'relative' }}>
      {widgetMode && !isSmallScreen && renderLanguageSelector()}
      <Box
        sx={widgetMode ? styles.sophieVideoWidget : styles.sophieVideo}
        as="video"
        ref={videoRef}
        id="sophieVideo"
        playsInline
        autoPlay
        muted
        loop
        poster={shouldShowPortraitVideo ? portaitImg : landingImg}
      >
        <source
          src={shouldShowPortraitVideo ? portaitVideo : landingVideo}
          type="video/mp4"
        />
      </Box>
    </Box>
  )
}

interface HomeProps {
  startSession: (speak: boolean) => void
  restart?: () => void
  headingMessage: string
  disableDigitalHuman: boolean
  logo: string
  widgetMode?: boolean
  embeddedMode: boolean
  languageSelected: string
  supportedLanguages: LanguageOption[]
}
const Home: React.FC<HomeProps> = ({
  startSession,
  restart = () => {},
  headingMessage,
  disableDigitalHuman,
  logo,
  widgetMode = false,
  embeddedMode,
  languageSelected,
  supportedLanguages
}) => {
  const { isBrowserSupported } = useSupportedBrowser()
  const isSmallScreen = isTablet || isMobileOnly
  const { t } = useTranslation()
  const [showUnsupportedAlert, setShowUnsupportedAlert] = useState(false)

  const StartButton = ({ speak, sx, ...props }: any) => {
    const handleClick =
      speak && !isBrowserSupported
        ? () => setShowUnsupportedAlert(true)
        : () => startSession(speak)
    return (
      <Button
        {...props}
        onClick={handleClick}
        sx={{ ...styles.letsChatButton, ...sx }}
      >
        {t(speak ? 'Home.audioButton' : 'Home.chatButton')}
      </Button>
    )
  }
  const LanguageSelectorComp = () => (
    <LanguageSelector
      languageSelected={languageSelected}
      supportedLanguages={supportedLanguages}
    />
  )
  return (
    <Flex
      sx={{
        ...styles.mainContainer,
        ...(widgetMode
          ? {
              justifyContent: 'flex-end',
              position: 'static',
              flexDirection: 'column'
            }
          : {})
      }}
    >
      <SophieVideo
        widgetMode={widgetMode}
        desktopOnly={embeddedMode}
        renderLanguageSelector={() => <LanguageSelectorComp />}
        isSmallScreen={isSmallScreen}
      />
      {showUnsupportedAlert && (
        <MayaUnsupportedBrowser
          close={() => setShowUnsupportedAlert(false)}
          continueChatMode={() => {
            setShowUnsupportedAlert(false)
            startSession(false)
          }}
        />
      )}
      {logo && !widgetMode && (
        <Box sx={styles.logo}>
          <Image src={logo} alt="Logo"></Image>
        </Box>
      )}
      {!widgetMode && !isSmallScreen && <LanguageSelectorComp />}
      <Flex
        sx={{
          ...styles.textContainer,
          ...(widgetMode
            ? {
                m: 0
              }
            : {})
        }}
      >
        {isSmallScreen && (
          <LanguageSelector
            languageSelected={languageSelected}
            supportedLanguages={supportedLanguages}
            sx={{
              top: '-2.25rem',
              right: '0.5rem'
            }}
          />
        )}
        <Text sx={styles.title}>
          <Trans i18nKey="Home.welcome" components={{ bold: <strong /> }} />
          <br />
          {t('Home.headingMessage') || headingMessage}
        </Text>

        {headingMessage && (
          <Flex sx={styles.actions}>
            {!disableDigitalHuman && (
              <StartButton id="audio" variant="primary" speak={true} />
            )}
            <StartButton id="chat" variant="whiteInverted" speak={false} />
          </Flex>
        )}
        <Flex sx={widgetMode ? { justifyContent: 'space-between' } : {}} mt={4}>
          <Text sx={styles.subtitle}>
            <Trans
              i18nKey="Home.privacy"
              components={{
                privacylink: (
                  <Text
                    as="a"
                    href="https://mayamd.ai/privacy-policy/"
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={styles.privacyPolicyLink}
                  />
                ),
                termslink: (
                  <Text
                    as="a"
                    href="https://mayamd.ai/terms-and-conditions/"
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={styles.privacyPolicyLink}
                  />
                )
              }}
            />
          </Text>
          {widgetMode && (
            <MayaCloseButton
              customStyles={{ alignSelf: 'center' }}
              onClick={restart}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
export default Home
