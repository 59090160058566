import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'
import { Flex } from 'rebass'
import { isTablet, isMobileOnly, isAndroid } from 'react-device-detect'
import { Uneeq } from 'uneeq-js'
import {
  UneeqAvatar,
  UneeqLocalVideo,
  UneeqProvider,
  UneeqContext,
  useUneeqState,
  useIsSmallScreen,
  usePortraitScreen
} from 'uneeq-react-core'
import {
  PrivacySummary,
  EndSessionConfirm,
  EscalationForm
} from 'uneeq-react-ui'
import config from '../../config'
import { loadingGatewayReducer } from '../../customPlugins/LoadingGateway'
import mayaEchoMiddleware from '../../customPlugins/mayaEchoMiddleware'
import mayaErrorsReducer from '../../customPlugins/MayaError/mayaErrorsReducer'
import MayaInformation from '../../customPlugins/MayaInformation/MayaInformation'
import mayaInformationMiddleware from '../../customPlugins/MayaInformation/mayaInformationMiddleware'
import mayaTranscriptReducer from '../../customPlugins/MayaTranscript/mayaTranscriptReducer'
import mayaStatementReducer from '../../customPlugins/MayaStatement/mayaStatementReducer'
import mayaMapReducer from '../../customPlugins/MayaMap/mayaMapReducer'
import MayaStatement from '../../customPlugins/MayaStatement/MayaStatement'
import sessionIDReducer from '../../customPlugins/SessionID/sessionIDReducer'
import MayaUi from '../../customPlugins/MayaUi'
import { questionReducer } from '../../customPlugins/Question'
import Question from '../../customPlugins/Question/Question'
import { getToken, endSession as endMayaSession } from '../../socket'
import mayaInformationReducer from '../../customPlugins/MayaInformation/mayaInformationReducer'
import MayaErrors from '../../customPlugins/MayaErrors/MayaErrors'
import assets from '../assets'
import UIToolbar from '../UIToolbar'
import closeSessionMiddleware from '../../customPlugins/CloseSession/closeSessionMiddleware'
import MayaTimeout from '../../customPlugins/MayaTimeout'
import styles from './styles'
import SmallScreen from './SmallScreen'
import mayaConfigReducer from '../../customPlugins/MayaConfig/mayaConfigReducer'
import pauseSessionMiddleware from '../../customPlugins/PauseSession/pauseSessionMiddleware'
import pauseSessionReducer from '../../customPlugins/PauseSession/pauseSessionReducer'
import {
  mayaBandwidthReducer,
  MayaBandwidth
} from '../../customPlugins/MayaBandwidth'
import Transcript from '../../customPlugins/MayaTranscript/Transcript'
import {
  MayaConfirmLeave,
  mayaConfirmLeaveReducer
} from '../../customPlugins/MayaConfirmLeave'
import {
  MayaDocumentTitle,
  mayaDocumentTitleReducer
} from '../../customPlugins/MayaDocumentTitle'
import sessionIDMiddleware from '../../customPlugins/SessionID/sessionIDMiddleware'
import Gateway from '../../customPlugins/LoadingGateway/Gateway'
import useAnswerQuestionTimeout from '../hooks/useAnswerQuestionTimeout'
import { MayaMap } from '../../customPlugins/MayaMap'
import {
  MayaExitSession,
  mayaExitSessionMiddleware,
  mayaExitSessionReducer
} from '../../customPlugins/MayaExitSession'
import {
  MayaConfirmRestart,
  mayaConfirmRestartReducer
} from '../../customPlugins/MayaConfirmRestart'

const {
  video: { permissions }
} = assets

// state slice to close all modals
export const closeModals = {
  menuOpen: false,
  settingsOpen: false,
  endConfirmOpen: false,
  timeoutOpen: false,
  confirmLeave: false,
  privacyOpen: false,
  error: null
}

// state slice to close all dialogs
export const closeDialogs = {
  question: null,
  transcriptOpen: false,
  error: null
}
const reducers = [
  mayaTranscriptReducer({
    onOpen: { ...closeModals, ...closeDialogs }
  }),
  mayaErrorsReducer,
  mayaMapReducer(),
  pauseSessionReducer(),
  questionReducer(),
  mayaStatementReducer(),
  mayaBandwidthReducer(config.maxLoadingTimeInMs),
  loadingGatewayReducer(),
  mayaConfigReducer(),
  mayaInformationReducer(),
  mayaConfirmLeaveReducer,
  mayaConfirmRestartReducer,
  mayaDocumentTitleReducer(config.defaultDocumentTitle),
  mayaExitSessionReducer(),
  sessionIDReducer
]

const debugMiddleware = () => (state: any, action: any) => {
  console.info(action)
  return state
}
const middleware: any = [
  debugMiddleware(),
  mayaEchoMiddleware(),
  mayaInformationMiddleware(),
  closeSessionMiddleware({ endMayaSession }),
  pauseSessionMiddleware(),
  sessionIDMiddleware(),
  mayaExitSessionMiddleware({ endMayaSession })
]

interface DigitalHumanContentProps {
  restart: () => void
  speak?: boolean
  widgetMode: boolean
  fullscreen?: boolean
  embeddedMode: boolean
}

const getRootNode = (): HTMLElement => {
  const rootNode = document.getElementById('mayaChatWidget')

  if (rootNode?.shadowRoot) {
    const shadowDomRoot = rootNode.shadowRoot.getElementById('react-root')
    return shadowDomRoot ? shadowDomRoot : document.body
  }

  return rootNode ? rootNode : document.body
}

const GatewayChildren = ({ finalRestart, widgetMode }: any) => {
  const rootNode = getRootNode()

  return (
    <>
      <UIToolbar />

      {/* Modals */}

      {ReactDOM.createPortal(
        <MayaConfirmLeave onConfirm={finalRestart} />,
        rootNode
      )}
      {ReactDOM.createPortal(<MayaConfirmRestart />, rootNode)}
      {!widgetMode && <MayaTimeout endSession={finalRestart} />}
      <PrivacySummary />
      {ReactDOM.createPortal(
        <EndSessionConfirm restart={finalRestart} />,
        rootNode
      )}
      {ReactDOM.createPortal(
        <ReactTooltip className="react-tooltip-zindex" />,
        rootNode
      )}
      <EscalationForm restart={finalRestart} />
      <MayaExitSession />
    </>
  )
}

const DigitalHumanContent = ({
  speak,
  widgetMode,
  fullscreen = false,
  embeddedMode
}: DigitalHumanContentProps) => {
  const { dispatch } = useContext(UneeqContext)
  const portraitScreen = usePortraitScreen()
  const { sessionPaused, transcriptOpen, loadingVideoActive } = useUneeqState()
  useAnswerQuestionTimeout(config.maxTimeWaitingForAnswer)
  const isSmallScreen = useIsSmallScreen()
  useEffect(() => {
    dispatch({ type: 'setSpeak', payload: speak })
  }, [speak, dispatch])
  const { restartSession } = useContext(UneeqContext)

  const finalRestart = () => {
    endMayaSession()
    restartSession()
  }

  const isTouchDevice = isTablet || isMobileOnly

  const orientation =
    // eslint-disable-next-line no-restricted-globals
    (screen.orientation || {}).type ||
    // eslint-disable-next-line no-restricted-globals
    (screen as any).mozOrientation ||
    // eslint-disable-next-line no-restricted-globals
    (screen as any).msOrientation
  const isLandscape =
    orientation === 'landscape-primary' || orientation === 'landscape-secondary'

  return (
    <Flex
      sx={{
        ...styles.container,
        ...(isTouchDevice
          ? !isAndroid
            ? {
                '@media only screen and (orientation: landscape)': {
                  '& > div': {
                    display: 'none'
                  },
                  '& > div:first-child': {
                    display: 'flex'
                  }
                }
              }
            : isLandscape
            ? {
                '& > div': {
                  display: 'none'
                },
                '& > div:first-child': {
                  display: 'flex'
                }
              }
            : {}
          : {}),
        ...(widgetMode
          ? {
              position: 'static',
              overflow: 'hidden',
              justifyContent: 'flex-end'
            }
          : {})
      }}
    >
      <SmallScreen />
      {!widgetMode && (
        <UneeqAvatar
          sx={{
            height: 'auto',
            marginLeft: sessionPaused ? '-5000000px' : 0
          }}
          shouldUseDynamicWidth={
            isSmallScreen || (embeddedMode && portraitScreen)
          }
        />
      )}
      <MayaDocumentTitle />
      <MayaUi speak={speak} />
      <MayaMap />
      <Flex
        sx={{
          ...styles.interactionContainer,
          ...(transcriptOpen ? { height: '100%' } : {}),
          ...(!speak || sessionPaused
            ? {
                left: ['unset', 'unset', 'unset', 'unset', '50%', '50%'],
                transform: [
                  'translateX(0)',
                  'translateX(0)',
                  'translateX(0)',
                  'translateX(0)',
                  'translateX(-50%)',
                  'translateX(-50%)'
                ],
                height: '90%'
              }
            : {}),
          ...(widgetMode
            ? {
                position: 'static',
                transform: 'translateX(0)',
                p: 0,
                overflow: 'hidden',
                height: fullscreen
                  ? '100%'
                  : ['75vh', '75vh', '75vh', '75vh', '85vh', '85vh']
              }
            : {})
        }}
      >
        <MayaBandwidth />
        {!loadingVideoActive && (
          <>
            <Question speak={speak} />
            <MayaInformation />
          </>
        )}
        <Transcript />

        {widgetMode && (
          <UneeqAvatar
            sx={{
              height: 'auto',
              flex: 1,
              display: transcriptOpen ? 'none' : 'flex',
              marginLeft: sessionPaused ? '-5000000px' : 0,
              borderRadius: transcriptOpen ? 'unset' : 'card',
              position: transcriptOpen ? 'absolute' : 'initial'
            }}
            shouldUseDynamicWidth
          />
        )}

        {widgetMode && (
          <Gateway
            showLoadingVideo={Boolean(speak)}
            restart={finalRestart}
            video={permissions}
            widgetMode
          >
            <GatewayChildren
              widgetMode={widgetMode}
              finalRestart={finalRestart}
            />
          </Gateway>
        )}
      </Flex>
      <MayaStatement />
      {/* Must be present but we want it hidden */}
      <UneeqLocalVideo style={{ display: 'none' }} />

      {ReactDOM.createPortal(<MayaErrors />, getRootNode())}
      {!widgetMode && (
        <Gateway
          showLoadingVideo={Boolean(speak)}
          restart={finalRestart}
          video={permissions}
        >
          <GatewayChildren
            widgetMode={widgetMode}
            finalRestart={finalRestart}
          />
        </Gateway>
      )}
      {/* {widgetMode && (
        <GatewayChildren widgetMode={widgetMode} finalRestart={finalRestart} />
      )} */}
    </Flex>
  )
}

const DigitalHuman = ({
  postInit,
  speak,
  onTimedOut,
  onSessionEnded,
  restart,
  widgetMode = false,
  fullscreen,
  setFullscreen,
  embeddedMode,
  personaId,
  testTimeoutTime
}: DigitalHumanProps) => {
  return (
    <UneeqProvider
      config={{
        ...config,
        conversationId: personaId ? personaId : config.conversationId,
        timeout: testTimeoutTime ? testTimeoutTime : config.timeout,
        timeoutWarning: testTimeoutTime
          ? testTimeoutTime - 1000
          : config.timeoutWarning
      }}
      reducers={reducers}
      speak={speak}
      middleware={middleware}
      getToken={getToken}
      postInit={postInit}
      restart={restart}
      onTimedOut={onTimedOut}
      onSessionEnded={onSessionEnded}
      fullscreen={fullscreen}
      setFullscreen={setFullscreen}
    >
      <DigitalHumanContent
        speak={speak}
        restart={restart}
        widgetMode={widgetMode}
        fullscreen={fullscreen}
        embeddedMode={embeddedMode}
      />
    </UneeqProvider>
  )
}

interface DigitalHumanProps {
  onTimedOut: () => void
  onSessionEnded: () => void
  postInit?: (uneeq: Uneeq) => void
  token?: string
  speak?: boolean
  restart: () => void
  widgetMode?: boolean
  fullscreen?: boolean
  setFullscreen?: Dispatch<SetStateAction<boolean>>
  embeddedMode: boolean
  personaId: string
  testTimeoutTime?: number
}

export default DigitalHuman
